.viewer-wrapper {
  width: 100%;
  background: black;
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.viewer-wrapper:before {
  content: "";
  display: block;
  padding-top: calc( 9 / 16 * 100% );
}

.viewer video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*
.viewer img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
*/

.viewer-wrapper .cursor-none {
  display: none;
}

.viewer-wrapper:fullscreen .cursor-none {
  display: initial;
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 1001;
  cursor: none;
}

.viewer-wrapper .corner-tl {
  top: 0px;
  left: 0px;
}

.viewer-wrapper .corner-tr {
  top: 0px;
  right: 0px;
}

.viewer-wrapper .corner-bl {
  bottom: 0px;
  left: 0px;
}

.viewer-wrapper .corner-br {
  bottom: 0px;
  right: 0px;
}
/*
.viewer-wrapper .db-logo {
  position: absolute;
  background: rgba( 255,255,255,0.75);
  bottom: 4px;
  right: 7.5px;
  z-index: 1001;
  cursor: pointer;
}

.viewer-wrapper .svl-logo {
  position: absolute;
  top: 9.5px;
  right: 7px;
  z-index: 1001;
  cursor: pointer;
}

.viewer-wrapper .svl-logo img {
  opacity: 0.1;
  filter: drop-shadow(0 0 5px rgb(0, 0, 0));
}
*/
.viewer-wrapper .watermark-logo {
  position: absolute;
/*  top: 9.5px;*/
  bottom: 80px;
  right: 7px;
  z-index: 1001;
  cursor: pointer;
}

.viewer-wrapper .watermark-logo img {
  opacity: 0.5;
  filter: drop-shadow(0 0 5px rgb(0, 0, 0));
}

.viewer-wrapper .controller {
  position: absolute;
  background: rgba( 0, 0, 0, 0.15);
  width: 100%;
  bottom: 1px;
  left: 1px;
  z-index: 1000;
}

.viewer-wrapper .btn-zoomin {
  position: absolute;
  background: rgba( 0,0,0,0.25);
  top: 100px;
  right: 7px;
  z-index: 1000;
}

.viewer-wrapper .btn-zoomout {
  position: absolute;
  background: rgba( 0,0,0,0.25);
  top: 150px;
  right: 7px;
  z-index: 1000;
}

.zoom-buttons {
  text-align: right;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}

.viewer-btn-wrapper {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 901;
}

.loader-wrapper {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 902;
}

/* ref. https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
.keyboard-shortcut {
  font-size: 120%;
  font-family: monospace;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
