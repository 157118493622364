.MediaController {
  height: 2em;
  background: #c10026;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.MediaController .switches {
  width: 200px;
  height: 2em;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.MediaController .right-buttons {
  width: 100px;
  height: 2em;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}